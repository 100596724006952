<script>
	import { fade } from "svelte/transition";
	import { push } from "svelte-spa-router";
	import Router from "svelte-spa-router";
	import Layout from "../../common/layout/Default.svelte";
	import DataList from "../../common/DataDisplay/List.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import SearchBar from "../../common/SearchBar.svelte";

	import List from "./List.svelte";
	import Add from "./Add.svelte";
	import Edit from "./Edit.svelte";
	import Detail from "./Detail.svelte";
	import Stores from "./Stores.svelte";
	import Campaigns from "./Campaigns.svelte";
	import { onMount } from "svelte";
	import { debounce } from "lodash";
	import { connection } from "../../store";

	export let params = {};

	const prefix = "/products";
	const routes = {
		"/": List,
		"/add": Add,
		"/:_id/stores": Stores,
		"/:_id/campaigns": Campaigns,
		"/:_id/edit": Edit,
		"/:_id/detail": Detail,
	};

	let isLoading = true;
	let search = "";
	$: dataId = params.wild?.split("/")[0];

	let data;
	let pageNumber = 1;
	let pageSize = 40;
	let dataFiltered;
	let loadingList = false;
	let isEndList = false;

	$: if (dataId) {
		dataFiltered = mappingData(data);
	}

	onMount(() => {
		loadData();
	});

	function getMoreItems(e) {
		if (e.detail.target.scrollHeight - e.detail.target.scrollTop <= e.detail.target.clientHeight + 20 && !isEndList) {
			pageNumber += 1;

			loadingList = true;
			_debounceLoadData();
		}
	}
	const _debounceLoadData = debounce(function (e) {
		loadData();
	}, 1000);

	async function loadData() {
		const keyword = search.trim().toLowerCase();
		await $connection
			.call("products.all.forListAdmin", pageNumber, pageSize, keyword)
			.then((result) => {
				if (result) {
					isEndList = data?.length == result.length;
					data = result;
					dataFiltered = mappingData(result);
					isLoading = false;
					loadingList = false;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function handleSearch(e) {
		loadData();
	}

	function mappingData(res) {
		if (res != undefined) {
			const active = res.filter((e) => e["status"] == "ACTIVE");
			const inactive = res.filter((e) => e["status"] == "INACTIVE");
			return [
				{
					category: `Active (${active.length})`,
					items: active.map((item) => productMap(item)),
				},
				{
					category: `InActive (${inactive.length})`,
					items: inactive.map((item) => productMap(item)),
				},
			];
		} else {
			return [
				{
					category: `Active (0)`,
					items: [],
				},
				{
					category: `InActive (0)`,
					items: [],
				},
			];
		}
	}

	function productMap(product) {
		return {
			_id: product._id,
			title: product.name,
			icon: "bx bx-box",
			active: product._id == dataId,
		};
	}

	function handleItemClick(e) {
		search = "";
		dataId = e.detail._id;
		push(`${prefix}/${e.detail._id}/detail`);
	}
	function routeEvent() {
		loadData();
	}
</script>

<Layout title="Product">
	<div class="flex flex-col md:flex-row md:h-full">
		<div class="flex flex-col md:h-full w-full md:w-1/4 py-3 pl-3 pr-1.5">
			<SearchBar
				bind:value={search}
				on:input={debounce(function (e) {
					handleSearch();
				}, 1000)}
				actionOne
				on:click-one={() => push(`${prefix}/add`)}
			/>
			{#if isLoading}
				<div class="my-2">
					<LoadingText />
				</div>
			{:else}
				<DataList
					{loadingList}
					on:scroll={(e) => getMoreItems(e)}
					category={true}
					items={dataFiltered}
					on:item-click={(e) => handleItemClick(e)}
				/>
			{/if}
		</div>
		<div class="w-full md:h-full flex-1 overflow-auto pr-3 py-3 pl-1.5" in:fade={{ duration: 500 }}>
			<div class="relative bg-white rounded-lg shadow-md min-w-full min-h-full">
				<Router {routes} {prefix} on:routeEvent={routeEvent} />
			</div>
		</div>
	</div>
</Layout>
