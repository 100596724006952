<script>
	import { replace } from "svelte-spa-router";
	import Template from "./templates/FormTemplate.svelte";
	import { connection } from "../../store";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import Loading from "../../common/Loading.svelte";
	import { createEventDispatcher } from "svelte";

	let isTopping = false;
	let isLoading;
	const dispatch = createEventDispatcher();

	let name = field("name", "", [required()]);
	let desc = field("desc", "", [required()]);
	let printable = field("printable", ["kitchen", "bar", "bakery"], [checkEmptyArray()]);
	let formAdd = form(name, desc, printable);
	let errors = [];
	async function handleSubmit(e) {
		isLoading.toggle();
		await formAdd.validate();
		const valid = $formAdd.valid;
		if (valid) {
			const { name, desc, isTopping, printable } = e.detail;
			$connection
				.call("productCategories.add", name, desc, isTopping, printable)
				.then((result) => {
					dispatch("routeEvent");
					setTimeout(() => {
						replace(`/category/${result}`);
					}, 1000);
					window.pushToast("Successfully Add Product Category.", "success");
				})
				.catch((error) => {
					window.pushToast(error, "danger");
				});
		} else {
			errors = $formAdd.errors;
			window.pushToast("Something Wrong! Please check again form field.", "danger");
		}
		isLoading.toggle();
	}
	function checkEmptyArray() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

<Loading text="Loading..." bind:this={isLoading} />

<Template
	bind:errors
	bind:name={$name.value}
	bind:desc={$desc.value}
	bind:printable={$printable.value}
	bind:isTopping
	on:submit={handleSubmit}
/>
