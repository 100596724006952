<script lang="ts">
  import { addMinutes } from "date-fns";
  import Cookies from "js-cookie";
  import routes from "./routes";
  import Router, { replace } from "svelte-spa-router";

  import {
    connection,
    loggedInUserId,
    store,
    trueUser,
    user,
    transactions as transactionLocal,
  } from "./store";
  import Loading from "./common/Loading.svelte";

  //external css
  import "flatpickr/dist/flatpickr.min.css";

  let trueUserRole;
  let storeSub;
  let isLoading;

  connection.subscribe((server) => {
    (async () => {
      await server.connect();
      // connection is ready here
      const token = Cookies.get("token");
      if (token !== null || token !== undefined) {
        await server.login({ resume: token });
        loggedInUserId.set(server.userId);
        // replace("/");
      }
    })();

    server.on("connected", () => {
      // do something
      console.log("connected");
    });
    server.on("login", async (m) => {
      isLoading.toggle();
      console.log("User logged in as", m);
      loggedInUserId.set(m.id);
      Cookies.set("token", m.token, { expires: 1 });
      await afterLogin(m);
    });
    server.on("logout", () => {
      isLoading.toggle();
      localStorage.setItem("user", null);
      localStorage.setItem("token", null);
      loggedInUserId.set(null);
      trueUser.set(null);
      Cookies.remove("token");
      console.log("User logged out");
      window.location.reload();
    });
    server.on("loginSessionLost", (id) =>
      console.error(
        `User {id} lost connection to server, will auto resume by default with token`
      )
    );
    server.on("disconnected", () => {
      // for example show alert to user
      console.log("disconnected");
    });
    server.on("error", (e) => {
      // global errors from server
      console.error("error:", e);
    });
    server.on("loginResume", (m) => {
      isLoading.toggle();
      console.log("User resumed (logged in by token)");
      afterLogin(m);
    });
    server.on("loginResumeFailed", (m) => {
      isLoading.toggle();
      console.log(
        "Failed to resume authorization with token after reconnection ",
        m
      );
    });

    async function afterLogin(m) {
      console.log("after login");
      let userCollection = server.collection("users").reactive().one();
      userCollection.onChange((data) => {
        user.set(data);
      });

      $connection.call("role.isCurrentInRoles.forLite").then((e) => {
        trueUserRole = e;
        trueUser.set(trueUserRole);
        console.log("after cek role");
        if (trueUserRole) {
          replace("/");
        } else {
          replace("/unauthorized");
        }
      });
      isLoading.toggle();
    }
  });

  function handleConditionFailed(event) {
    if (event.detail.userData.userId == undefined) {
      replace("/login");
    }
  }
</script>

<Loading text="Loading..." bind:this={isLoading} />

<main>
  <Router {routes} on:conditionsFailed={handleConditionFailed} />
</main>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .toggle-checkbox:checked {
    @apply right-0 border-white;
    right: 0;
    border-color: #68d391;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply bg-green-400;
    background-color: #68d391;
  }

  @media print {
    #print-screen {
      padding: 0;
    }
    #print-container {
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;
    }
    .hide-print {
      display: none;
    }
    @page {
      margin: 0.5rem;
      size: 210mm x 297mm;
      size: portrait;
    }
  }
</style>
