<script>
	import Input from "../../../common/control/Input.svelte";
	import Switch from "../../../common/control/Switch.svelte";
	import Select from "../../../common/control/Select.svelte";
	import MoneyInput from "../../../common/control/MoneyInput.svelte";
	import Button from "../../../common/control/Button.svelte";
	import CheckList from "../../../common/control/CheckList.svelte";
	import MediaObject from "../../../common/DataDisplay/MediaObject.svelte";
	import TextArea from "../../../common/control/TextArea.svelte";
	import Modal from "../../../common/Modal.svelte";
	import ModalIngredient from "../../../common/Modal/ModalIngredient.svelte";
	import ModalTopping from "../../../common/Modal/ModalTopping.svelte";
	import Header from "../../../common/Header.svelte";
	import { formatNumber, formatRupiah } from "../../../common/helper/FormHelper";
	import { createEventDispatcher, onMount } from "svelte";
	import { noImg } from "../../../common/helper/StringHelper";
	import { connection } from "../../../store";
	const dispatch = createEventDispatcher();

	let isLoading = true;
	let modalConfirmDelete;
	let modalIngredient;
	let productAdditionals = [];
	let categories = [];
	let selectedIngredients = [];
	let selectedToppings = [];
	let tempDeleteToppings = null;


	onMount(() => {
		loadData();
	});

	function loadData() {
		$connection.call("products.all.toppings").then((result) => {
			productAdditionals = result["additionals"];
			categories = result["categories"];
			selectedToppings = toppings;
			selectedIngredients = ingredients;
			isLoading = false;
		});
	}

	$: categorySelected = categories.filter((e) => e._id == category)[0];

	export let id = undefined;
	export let name;
	export let price;
	export let priceOnline;
	export let activeInApp;
	export let sku;
	export let desc;
	export let images;
	let image = images.length > 0 ? images[0] : "";
	export let category = null;
	export let additionals = [];
	export let toppings = [];
	export let ingredients = [];
	export let errors = [];
	export let mode;

	let modalTopping;
	let selectedProductIds = [];

	function handleSubmit() {
		const priceMap = Number.isNaN(formatNumber(price)) ? 0 : formatNumber(price);
		const priceOnlineMap = Number.isNaN(priceOnline) ? "" : formatNumber(priceOnline);
		images.length > 0 ? (images[0] = image) : images.push(image);
		if (isItemNotValid()) {
			window.pushToast("Item can not lower than 0.", "danger");
			return;
		}
		const ingMap = selectedIngredients.map((item) => {
			if (item.isSelected) delete item.isSelected;
			return { ...item };
		});
		let submittedData = {
			category,
			name,
			priceMap,
			sku,
			desc,
			images,
			additionals,
			toppings: selectedToppings.map((item) => {
				if (item.isSelected) delete item.isSelected;
				return { ...item };
			}),
			ingMap,
			priceOnlineMap,
		};
		if (mode == "Add") {
			submittedData = {
				...submittedData,
				activeInApp,
			};
		}

		dispatch("submit", submittedData);
	}

	function isItemNotValid() {
		if (selectedIngredients.some((item) => item.measure < 0)) {
			return true;
		} else if (selectedIngredients.some((item) => !Number.isInteger(item.measure))) {
			return true;
		} else {
			return false;
		}
	}

	function handleRemoveTopping() {
		selectedToppings = selectedToppings.filter((item) => item._id != tempDeleteToppings._id);
		window.pushToast("Successfully Deleted Topping.", "success");
		tempDeleteToppings = null;
		modalConfirmDelete.toggle();
	}

	function mapOptions(item) {
		return {
			id: item._id,
			name: item.name,
		};
	}

	const onClickAddToppings = () => {
		modalTopping.open();
	};

	const onClickAddIngredients = () => {
		modalIngredient.open();
	};
</script>

<Modal
	size="small"
	title="Delete Topping"
	confirm
	bind:this={modalConfirmDelete}
	on:submit={() => handleRemoveTopping()}
	on:cancel={() => {
		tempDeleteToppings = null;
		modalConfirmDelete.toggle();
	}}
	closed
	on:close={() => {
		tempDeleteToppings = null;
		modalConfirmDelete.toggle();
	}}
/>

<ModalTopping bind:this={modalTopping} bind:selectedToppings></ModalTopping>

<ModalIngredient bind:this={modalIngredient} bind:selectedIngredients></ModalIngredient>

{#if isLoading}
	<div class="flex space-x-1 items-center">
		<i class="bx bx-loader-circle bx-spin text-xl" />
		<p>Loading..</p>
	</div>
{:else}
	<Header title={id ? "Edit Product" : "Add Product"}>
		<Button leftIcon="bx bx-save" on:click={() => handleSubmit()}>Save Changes</Button>
	</Header>
	<div class="p-4">
		<MediaObject
			bind:image
			title="Product Image"
			description="(jpg, png, jpeg)"
			size="medium"
			actionLabel="Upload Image"
			actionType="file"
		/>
		<div class="flex flex-row mt-4 space-x-2">
			<div class="flex-1">
				<Input bind:errors label="Name" placeholder="ex: Boba" bind:value={name} name="name" />
			</div>
			<div class="flex-1">
				<Input bind:errors label="SKU" placeholder="ex: 001" bind:value={sku} name="sku" />
			</div>
		</div>
		<div class="flex flex-row mt-4 space-x-2">
			<div class="flex-1">
				<!-- {#if isLoadingCategory}
                    <i class="bx bx-loader-circle bx-spin text-xl" />
                {:else} -->
				<Select
					bind:errors
					name="category"
					textHint="Choose Category"
					options={categories.map((item) => mapOptions(item))}
					label="Category"
					selected={category}
					fullWidth
					on:change={(e) => {
						category = e.detail;
					}}
				/>
				<!-- {/if} -->
			</div>
			<div class="flex-1">
				<MoneyInput bind:errors label="Price" placeholder="ex: 1000" bind:value={price} name="price" />
			</div>
			<div class="flex-1">
				<MoneyInput bind:errors label="Ojol Price" placeholder="ex: 1000" bind:value={priceOnline} name="priceOnline" />
			</div>
		</div>
		<div class="flex flex-row mt-4">
			<div class="flex-1">
				<TextArea bind:errors label="Description" name="desc" bind:value={desc} maxlength="100" />
			</div>
		</div>
		<hr class="border-gray-100 my-4" />
		<h4 class="text-gray-800 text-md mb-3 font-medium flex space-x-2">
			Ingredients <Button
				bind:errors
				size="small"
				name="ingredients"
				leftIcon="bx bx-plus"
				extClass="ml-2"
				on:click={() => onClickAddIngredients()}>Add Ingredients</Button
			>
		</h4>
		<div class="flex flex-col items-start space-y-2">
			<!-- {#if isLoadingItems}
                <i class="bx bx-loader-circle bx-spin text-xl" />
            {:else} -->
			{#if selectedIngredients.length == 0}
				<p class="text-gray-500 text-sm">No Ingredients</p>
			{/if}
			{#each selectedIngredients as ingredient, index}
				<div class="flex items-center space-x-2 w-full">
					<div class="flex-1">
						<Input noLabel placeholder="name ex: medium" value={ingredient.name} name="ingredient-name" disabled />
					</div>
					<div class="flex-1">
						<Input noLabel step="1" placeholder="ex: 10" bind:value={ingredient.measure} type="number">
							<b class="lowercase">{ingredient.measureType}</b>
						</Input>
					</div>
					<div class="flex space-x-2 flex-1 items-center">
						<Button
							leftIcon="bx bx-trash"
							on:click={() => {
								selectedIngredients = selectedIngredients.filter((item, idx) => idx != index);
							}}
						/>
					</div>
				</div>
			{/each}
			<!-- {/if} -->
		</div>

		{#if categorySelected && !categorySelected.isTopping}
			<hr class="border-gray-100 my-4" />
			<h4 class="text-gray-800 text-md mb-3 font-medium">Modifier</h4>
			<div class="w-full">
				<!-- {#if isLoadingAdditional}
                    <i class="bx bx-loader-circle bx-spin text-xl" />
                {:else} -->
				{#if productAdditionals.length == 0}
					<p class="text-gray-500 text-sm">No Modifier</p>
				{/if}
				<div class="grid grid-cols-4 gap-2">
					{#each productAdditionals.map( (item) => ({ _id: item._id, name: item.name, desc: item.desc, status: item.status, isRequired: item.isRequired || false, items: item.items.map( (it) => ({ ...it, ...{ active: false } }) ) }) ) as pa}
						<div class="flex-1">
							<div class="flex items-end gap-2">
								<Switch
									disabled={pa.status == "INACTIVE"}
									textRight={pa.name}
									value={pa}
									checked={additionals.find((item) => item._id == pa._id)}
									on:change={(e) => {
										const { value: selected } = e.detail;
										const index = additionals.findIndex((item) => item._id == selected._id);
										if (index < 0) {
											additionals.push({
												_id: selected._id,
												name: selected.name,
												desc: selected.desc,
												status: selected.status,
												items: selected.items.map((it) => ({
													...it,
													...{ active: it.default ? true : false },
												})),
												isRequired: selected.isRequired,
											});
											additionals = additionals;
										} else {
											additionals = additionals.filter((item) => item._id != selected._id);
										}
									}}
								/>
								{#if pa.isRequired}
									<div class="text-gray-600 text-sm italic font-bold">(Modifier Wajib)</div>
								{/if}
							</div>
							<div class="my-2" />
							<CheckList
								items={pa.items.map((item, index) => ({
									text: `${item.name} - (${formatRupiah(item.price, "Rp. ")})`,
									value: item.name,
									index: index,
									disabled:
										additionals.findIndex((item) => item._id == pa._id) < 0 ||
										pa.items[index].default ||
										pa.status == "INACTIVE",
								}))}
								checkedItems={additionals.find((item) => pa._id == item._id)
									? additionals
											.find((item) => pa._id == item._id)
											.items.filter((it) => it.active == true)
											.map((it) => it.name)
									: []}
								on:change={(e) => {
									const index = additionals.findIndex((it) => it._id == pa._id);
									if (index > -1) {
										additionals[index].items[e.detail.index].active = !additionals[index].items[e.detail.index].active;
										additionals = additionals;
									}
								}}
							/>
						</div>
					{/each}
				</div>
				<!-- {/if} -->
			</div>
			<hr class="border-gray-100 my-4" />
			<h4 class="text-gray-800 text-md mb-3 font-medium flex space-x-2">
				Topping <Button size="small" leftIcon="bx bx-plus" extClass="ml-2" on:click={() => onClickAddToppings()}>Add Topping</Button>
			</h4>
			<div class={selectedToppings.length == 0 ? "w-full" : "grid grid-cols-4 gap-4"}>
				{#if selectedToppings.length == 0}
					<p class="text-gray-500 text-sm w-full">No Topping Selected</p>
				{/if}
				{#each selectedToppings as topping}
					<div class="p-2 flex flex-col items-center justify-center border rounded-md">
						<img
							class="w-20 h-20 rounded-md object-cover"
							src={topping.images[0].url == "" ? noImg : topping.images[0].url}
							alt=".."
						/>
						<h4 class="text-gray-800 text-md mt-3 text-center">{topping.name}</h4>
						<p class="text-gray-800 text-sm font-medium mb-2">{formatRupiah(topping.price, "Rp. ")}</p>
						<Button
							size="small"
							status="danger"
							on:click={() => {
								tempDeleteToppings = selectedToppings.find((item, idx) => item._id == topping._id);
								modalConfirmDelete.toggle();
							}}>Remove</Button
						>
					</div>
				{/each}
			</div>
		{/if}
		<hr class="border-gray-100 my-4" />
		{#if mode == "Add"}
			<Switch
				bind:value={activeInApp}
				checked={activeInApp}
				name="active_in_app"
				text="Display In Mobile App"
				textRight={activeInApp ? "ENABLE" : "DISABLE"}
				on:change={(e) => {
					activeInApp = !activeInApp;
				}}
			/>
		{/if}
	</div>
{/if}
